body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Custom CSS */
div#comments {
  height: 100% !important;
  max-height: 100% !important;
}
.stream-block-main {
  align-items: center;
}
.live-stream-block div#remote-stream {
  max-height: 100%;
}
div#comments>div {
  background: linear-gradient(360deg, #0000FE -68.26%, #A8B9FF 171.15%);
  color: #fff;
}
.product-card-wapper {
  width: 100%;
}
div#comments>div * {
  color: #fff !important;
}
.live-admin-side>div {
  max-width: 100%;
}
.live-stream-block {
  padding: 24px;
}
.live-stream-block button {
  bottom: 40px;
}